
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import SideMenu from "./components/title/SideMenu.vue";
import HeadMenu from "./components/menu/HeadMenu.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    SideMenu,
    HeadMenu
},
});
