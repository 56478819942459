import { reactive } from "vue"


export default new class LayoutService {
    readonly MOBILE_BREAKPOINT = 1050;

    state = reactive({
        mobile: window.innerWidth <= this.MOBILE_BREAKPOINT
    });

    constructor() {
        window.matchMedia(`(max-width: ${this.MOBILE_BREAKPOINT}px)`).addEventListener('change', event => {
            this.state.mobile = event.matches;
        });
    }
}