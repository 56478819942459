
import { menuController } from "@ionic/core/components";
import { defineComponent } from "vue";
import { NavigationList } from "../header/Navigation";
import { IonMenu, IonList, IonItem, IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "SideMenu",
  components: {
    IonMenu,
    IonList,
    IonItem,
    IonIcon,
  },
  setup() {
    return {
      NavigationList,
    };
  },
  methods: {
    closeMenu() { 
       menuController.close();
    },
  },
});
